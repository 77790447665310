<template>
  <div
    v-if="$store.state.fastPermissoes.administrador == 'S' && $store.state.fastPermissoes.ativo == 'S'"
  >
    <div class="fd-app-wrap">
      <div class="container">
        <!-- breadcrumb -->
        <nav>
          <ul class="fd-app-breadcrumb">
            <li class="fd-app-breadcrumb-item">
              <a
                href="/minhas-plataformas"
                class="aluno_font_color"
                @click.prevent="$router.push('/minhas-plataformas')"
              >Minhas Plataformas</a>
            </li>
            <li class="fd-app-breadcrumb-item">
              <a
                class="aluno_font_color"
                :href="'/plataforma/' + $route.params.id_plataforma"
                @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma)"
              >Plataforma {{ $store.state.fastPlataforma.nome_plataforma }}</a>
            </li>
            <li class="fd-app-breadcrumb-item">
              <a
                class="aluno_font_color"
                :href="'/plataforma/' + $route.params.id_plataforma + '/admin'"
                @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma + '/admin')"
              >Modo admin</a>
            </li>
            <li class="fd-app-breadcrumb-item active">
              <a
                class="aluno_font_color"
                href="#"
              >Assinatura de documentos</a>
            </li>
          </ul>
        </nav>
        <!-- /breadcrumb -->
      </div>

      <div class="fd-mode-adm-content mt-4">
        <div class="container">
          <div class="row">
            <div
              id="body_conteudo_modo_admin"
              class="col-sm-12 col-md-12 fd-mode-adm-content-body col-lg-12 ml-0"
            >
              <!-- fd-app-welcome -->
              <section class="fd-app-welcome text-left mb-4">
                <h1 class="aluno_font_color">
                  Assinatura de
                  <span id="txtDashboardNomePlataforma">documentos</span>
                </h1>
                <p class="aluno_font_color my-0">Configure os gateways de assinaturas de documentos disponíveis na plataforma!</p>
                <img :src="require('@/assets/images/form-effect-01.png')">
              </section>
              <!-- /fd-app-welcome -->
              <div class="row">
                <div v-if="!$store.state.fastCarregando && fastGatewaysAssinaturasArray.length" class="col-12 fd-app-jobs">
                  <div class="row col-sm-12 col-md-12 col-lg-4 mt-4 mb-3">
                    <h4 class="aluno_font_color text-nowrap">
                      Lista de gateways suportados ({{fastGatewaysAssinaturasArray.length}})
                    </h4>
                  </div>                    
                  <div v-for="g in this.fastGatewaysAssinaturasArray" :key="g.id_assinatura_gateway">
                    <div class="card mb-4">
                      <div class="card-header text-center text-wrap">
                        <h4 class="pt-1">{{ g.nome_gateway }}</h4>
                      </div>
                      <div class="card-body pb-0">
                        <div class="col-sm-12 col-md-12 col-lg-12">
                          <div class="text-center">
                            <span v-if="g.disponivel" class="btn-novo btn-success text-white"><small>Ativado</small></span>
                            <span v-else class="btn-novo btn-dark text-white"><small>Desativado</small></span>
                          </div>
                          <div>
                            <div class="form-group">
                              <label for="nome-gateway">Gateway de assinaturas:</label>  
                              <input type="text" id="nome-gateway" class="form-control" :value=g.nome_gateway readonly>  
                            </div>
                            <div class="form-group">
                              <label for="descricao-gateway">Descrição:</label>  
                              <input type="text" id="descricao-gateway" class="form-control mx-auto" :value=g.descricao_gateway readonly>  
                            </div>
                          </div>
                          <div v-if="g.disponivel">
                            <div v-if="g.client_id_obrigatorio" class="form-group">
                              <label for="client-id-gateway">CLIENT ID:</label>  
                              <input v-if="g.usando_config_padrao" id="client-id-gateway" type="text" class="form-control mx-auto" v-model=g.client_id_padrao readonly>
                              <input v-else type="text" id="client-id-gateway" class="form-control mx-auto" v-model="g.client_id" readonly>
                            </div>
                            <div v-if="g.client_secret_obrigatorio" class="form-group">
                              <label for="client-secret-gateway">CLIENT SECRET:</label>  
                                <input v-if="g.usando_config_padrao" type="text" id="client-secret-gateway" class="form-control mx-auto" v-model=g.client_secret_padrao readonly>
                                <input v-else type="text" id="client-secret-gateway" class="form-control mx-auto" v-model="g.client_secret" readonly>
                            </div>
                          </div>
                          <div v-else class="text-center mt-3">
                            <span>
                              Este gateway de assinaturas ainda não está disponível para uso.
                              Configue para ativar.
                            </span>
                          </div>
                          <div class="row col-sm-12 col-md-12 col-lg-12 text-center my-3 d-flex justify-content-lg-around justify-content-md-around justify-content-sm-around justify-content-center w-75 mx-auto">
                            <a class="btn-novo btn-primary text-white text-nowrap my-1" @click="exibeModalConfigurarGateway(g)"><small>Configurar Gateway</small></a>
                            <a v-if="g.disponivel && !g.usando_config_padrao && g.webhook_configuravel && !g.webhook_configurado" class="btn-novo btn-danger text-white text-nowrap my-1 h5 blink_me" @click="exibeModalConfigurarWebhook(g)"><small>Configurar Webhook</small></a>
                            <a v-else-if="g.disponivel && !g.usando_config_padrao && g.webhook_configuravel" class="btn-novo btn-info text-white text-nowrap my-1" @click="exibeModalConfigurarWebhook(g)"><small>Configurar Webhook</small></a>
                            <a v-if="g.disponivel" class="btn-novo btn-secondary text-white text-nowrap my-1" @click="exibeModalVerificarConexao(g)"><small>Verificar conexão</small></a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div v-else class="row">
                  <div class="d-flex justify-content-center text-center my-5">
                    <b-icon icon="gear-fill" animation="spin" /> Carregando
                  </div>
                </div>
              </div>

              <!-- fd-app-meus-cursos -->
              <div class="mt-5 mb-5">
                <a
                  id="btn_voltar_modo_admin"
                  class="btn btn-red-hollow"
                  :href="'/plataforma/' + $route.params.id_plataforma + '/admin'"
                  @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma + '/admin')"
                >
                  <b-icon-arrow-return-left />
                  Voltar
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- modals -->
    <modal
      name="modalConfigurarGateway"
      :shift-y="0.1"
      height="auto"
      :scrollable="true"
      :adaptative="true"
      :width="modalWidthSm"
    >
      <div v-if="$store.state.fastCarregando" class="row">
        <div class="text-center my-5 mx-auto">
          <b-icon icon="gear-fill" animation="spin" /> Carregando
        </div>
      </div>
      <div v-else class="fd-app-table-novo-logs">
        <head class="col-12 d-flex justify-content-between">
          <div class="pl-3 pt-2">
            <h5>Configurar Gateway {{ fastGatewayAssinatura.nome_gateway }}</h5>
          </div>
          <div class="pr-3 pt-2">
            <a class="btn-novo btn-light" href="#" @click.prevent="hideModal('modalConfigurarGateway') ">
              <b-icon-arrow-return-left class="text-orange"/>
            </a>
          </div>
        </head>
        <div class="col-sm-12 col-md-12 col-lg-12 p-3">
          <div v-if="fastGatewayAssinatura.informacoes_tecnicas" class="my-2">
            <h6>
              {{fastGatewayAssinatura.informacoes_tecnicas}}
            </h6>
          </div>
          <div v-if="fastGatewayAssinatura.client_id_obrigatorio" class="my-2">
            <label for="input-client-id">CLIENT ID:</label>  
            <input type="text" id="input-client-id" class="form-control" v-model="fastGatewayAssinatura.client_id">
          </div>
          <div v-if="fastGatewayAssinatura.client_secret_obrigatorio" class="my-2">
            <label for="input-client-secret">CLIENT SECRET:</label>  
            <input type="text" id="input-client-secret" class="form-control" v-model="fastGatewayAssinatura.client_secret">
          </div>
          <div class="row col-sm-12 col-md-12 col-lg-12 text-center my-3 d-flex justify-content-lg-around justify-content-md-around justify-content-sm-around justify-content-center w-75 mx-auto">
            <a v-if="fastGatewayAssinatura.url_documentacao" class="btn-novo btn-primary text-white text-nowrap my-1" :href=fastGatewayAssinatura.url_documentacao target="_blank"><small>Informações Técnicas</small></a>
            <a class="btn-novo btn-success text-white text-nowrap my-1" @click="postAtualizaDadosGateway()"><small>Verificar e salvar</small></a>
            <a class="btn-novo btn-danger text-white text-nowrap my-1" @click="exibeModalRestaurarConfiguracoes()"><small>Restaurar configurações</small></a>
          </div>
        </div>
      </div>
    </modal>

    <modal
      name="modalRestaurarConfiguracoes"
      :shift-y="0.1"
      height="auto"
      :scrollable="true"
      :adaptative="true"
      :width="modalWidthMd"
    >
      <div v-if="$store.state.fastCarregando" class="row">
          <div class="text-center my-5 mx-auto">
            <b-icon icon="gear-fill" animation="spin" /> Carregando
          </div>
        </div>
      <div v-else class="fd-app-table-novo-logs">
        <head class="col-12 d-flex justify-content-between">
          <div class="pl-3 pt-2">
            <h5>Restaurar configurações de {{ fastGatewayAssinatura.nome_gateway }}</h5>
          </div>
          <div class="pr-3 pt-2">
            <a class="btn-novo btn-light" href="#" @click.prevent="hideModal('modalRestaurarConfiguracoes')">
              <b-icon-arrow-return-left class="text-orange"/>
            </a>
          </div>
        </head>
        <div class="col-sm-12 col-md-12 col-lg-12 p-3 text-center">
          Reseja realmente restaurar as configurações originais deste gateway de assinatura? As configurações atuais serão perdidas.
          <div class="my-2">
            <a class="btn-novo btn-danger text-white text-nowrap" @click="postRestauraPadraoGateway()"><small>Restaurar configurações</small></a>
          </div>
        </div>
      </div>
    </modal>

    <modal
      name="modalConfigurarWebhook"
      :shift-y="0.1"
      height="auto"
      :scrollable="true"
      :adaptative="true"
      :width="modalWidthSm"
    >
      <div v-if="$store.state.fastCarregando" class="row">
        <div class="text-center my-5 mx-auto">
          <b-icon icon="gear-fill" animation="spin" /> Carregando
        </div>
      </div>
      <div v-else class="fd-app-table-novo-logs">
        <head class="col-12 d-flex justify-content-between">
          <div class="pl-3 pt-2">
            <h5>Configurar Webhook {{ fastGatewayAssinatura.nome_gateway }}</h5>
          </div>
          <div class="pr-3 pt-2">
            <a class="btn-novo btn-light" href="#" @click.prevent="hideModal('modalConfigurarWebhook')">
              <b-icon-arrow-return-left class="text-orange"/>
            </a>
          </div>
        </head>
        <div class="col-sm-12 col-md-12 col-lg-12 p-3 text-center">
          <div v-if="fastGatewayAssinatura.webhook_informacoes_tecnicas" class="my-2">
            <h6>
              {{fastGatewayAssinatura.webhook_informacoes_tecnicas}}
            </h6>
          </div>
          <div class="row col-sm-12 col-md-12 col-lg-12 text-center my-3 d-flex justify-content-lg-around justify-content-md-around justify-content-sm-around justify-content-center w-75 mx-auto">
            <a v-if="fastGatewayAssinatura.webhook_url_documentacao" class="btn-novo btn-primary text-white text-nowrap my-1" :href=fastGatewayAssinatura.webhook_url_documentacao target="_blank"><small>Informações Técnicas</small></a>
            <a class="btn-novo btn-success text-white text-nowrap my-1" @click="exibeModalGerarWebhook()"><small>Gerar URL de Webhook</small></a>
          </div>
        </div>
      </div>
    </modal>

    <modal
      name="modalGerarWebhook"
      :shift-y="0.1"
      height="auto"
      :scrollable="true"
      :adaptative="true"
      :width="modalWidthLg"
    >
      <div v-if="$store.state.fastCarregando" class="row">
          <div class="text-center my-5 mx-auto">
            <b-icon icon="gear-fill" animation="spin" /> Carregando
          </div>
        </div>
      <div v-else class="fd-app-table-novo-logs">
        <head class="col-12 d-flex justify-content-between">
          <div class="pl-3 pt-2">
            <h5>Gerar token de webhook para {{ fastGatewayAssinatura.nome_gateway }}</h5>
          </div>
          <div class="pr-3 pt-2">
            <a class="btn-novo btn-light" href="#" @click.prevent="hideModal('modalGerarWebhook'), getGatewaysAssinatura()">
              <b-icon-arrow-return-left class="text-orange"/>
            </a>
          </div>
        </head>
        <div class="col-sm-12 col-md-12 col-lg-12 p-3 text-center">
          <p>
            <span class="btn-novo btn-warning">Atenção</span>
          </p>
          <p>
            Para que você possa concluir a configuração deste gateway de assinaturas é necessário gerar uma URL de Webhook e cadastrar na plataforma do gateway conforme instruções técnicas na respectiva documentação. Mas atenção, esse link é gerado apenas uma vez, portanto, salve caso precise futuramente. 
            O FastEAD não armazena essa informação, caso você clique para gerar novamente, será gerado um novo link e a configuração terá de ser refeita.
          </p>
          <div class="my-2">
            <a class="btn-novo btn-success text-white text-nowrap" @click="postGeraTokenWeb()"><small>Gerar URL de Webhook</small></a>
          </div>
        </div>
        <div v-if="fastTokenWebhook" class="p-3">
          <div v-if="!$store.state.fastCarregando" class="d-flex justify-content-center">
            <div class="input-group mb-3">
              <input 
                type="text" 
                id="url-webhook" 
                class="form-control" 
                v-model="fastTokenWebhook" 
                v-on:focus="$event.target.select()" 
                ref="urlwebhook" 
                readonly>
              <div class="input-group-append">
                <button class="btn btn-outline-secondary btn-copy" type="button" id="btn-copy" @click.prevent="copiaURLWebhook()"><b-icon-file-text/></button>
              </div>
            </div>        
          </div>
          <div v-else class="row">
            <div class="d-flex justify-content-center text-center my-5">
              <b-icon icon="gear-fill" animation="spin" /> Carregando
            </div>
          </div>
        </div>
      </div>
    </modal>

    <modal
      name="modalVerificarConexao"
      :shift-y="0.1"
      height="auto"
      :scrollable="true"
      :adaptative="true"
      :width="modalWidthSm"
    >
      <div v-if="$store.state.fastCarregando" class="row">
        <div class="text-center my-5 mx-auto">
          <b-icon icon="gear-fill" animation="spin" /> Carregando
        </div>
      </div>
      <div v-else class="fd-app-table-novo-logs">
        <head class="col-12 d-flex justify-content-between">
          <div class="pl-3 pt-2">
            <h5>Verificar alterações de {{ fastGatewayAssinatura.nome_gateway }}</h5>
          </div>
          <div class="pr-3 pt-2">
            <a class="btn-novo btn-light" href="#" @click.prevent="hideModal('modalVerificarConexao')">
              <b-icon-arrow-return-left class="text-orange"/>
            </a>
          </div>
        </head>
        <div v-if="!this.$store.state.fastCarregando && fastGatewayConexao === 'sucesso'" class="col-sm-12 col-md-12 col-lg-12 p-3 text-center">
          <div>
            <span class="btn-novo btn-success text-white">Sucesso</span>
          </div>
          <div>
            As credenciais são válidas, conexão verificada com sucesso.
          </div>
        </div>
        <div v-else-if="!this.$store.state.fastCarregando && fastGatewayConexao === 'erro'" class="col-sm-12 col-md-12 col-lg-12 p-3 text-center">
          <div>
            <span class="btn-novo btn-danger text-white">Erro</span>
          </div>
          <div>
            Erro na conexão, verifique os dados e tente novamente.
          </div>
        </div>
        <div v-else class="row">
          <div class="text-center my-5 mx-auto">
            <b-icon icon="gear-fill" animation="spin" /> Carregando
          </div>
        </div>
      </div>
    </modal>
    
    <!-- Notificações -->
    <notifications
      group="foo"
      position="bottom right"
    />
  </div>
</template>

<script>
import { settings } from "@/config";
import methods from "@/methods";
import VueCropper from "vue-cropperjs";
import "cropperjs/dist/cropper.css";

export default {
  name: "HomeInternoAdminIntegracoesAssinaturaDocumentos",
  components: {
    VueCropper,
  },
  mixins: [methods],
  data: function () {
    return {
      modalWidthSm: this.isMobile() ? "60%" : "50%",
      modalWidthMd: this.isMobile() ? "90%" : "50%",
      modalWidthLg: this.isMobile() ? "100%" : "90%",
      fastGatewaysAssinaturasArray: [],
      fastGatewayAssinatura: {
        id_assinatura_gateway: "",
        nome_gateway: "",
        codigo_gateway: "",
        descricao_gateway: "",
        padrao: "",
        config_obrigatorio: "",
        client_id_obrigatorio: "",
        client_secret_obrigatorio: "",
        url_documentacao: "",
        informacoes_tecnicas: "",
        client_id: "",
        client_secret: "",
        disponivel: "",
        usando_config_padrao: "",
        client_id_padrao: "",
        client_secret_padrao: "",
        url_api_padrao: "",
      },
      fastGatewayConexao: "",
      fastTokenWebhook: "",
    };
  },
  mounted: function () {
    if (this.$route.params.id_plataforma) {
      this.getFastDependecias(this.$route.params.id_plataforma, 'administrador')
        .then(() => {
          this.$store.state.fastCarregando = true;
          this.getGatewaysAssinatura();
          this.$store.state.fastCarregando = false;
        })
        .catch((e) => {
          this.exibeToasty(e, "error");
          console.log(e)
          this.$store.state.fastCarregando = false;
        });
    }
  },
  methods: {
    getGatewaysAssinatura () {
      this.$store.state.fastCarregando = true;
      this.promiseGetFastApi("api/fast_assinatura_gateway/lista_disponiveis_admin", `id_plataforma=${this.$route.params.id_plataforma}`)
      .then(res => {
        if (res.success === false) {
          console.log(res.error)
          console.log(res.description)
          this.exibeToasty(res.error + " " + res.description, "error")          
          this.$store.state.fastCarregando = false;
        } else {
          if (res.length) {
            this.$store.state.fastCarregando = false;
            this.fastGatewaysAssinaturasArray = res;
            this.$store.state.fastCarregando = false;
          }
        }
      }).catch(e => {
        console.log(e);
        this.exibeToasty(e, "error");
        this.$store.state.fastCarregando = false;
      })
    },
    getVerificaConexaoGatewayAssinatura () {
      this.$store.state.fastCarregando = true;
      this.fastGatewayConexao = "aguardando";
      this.promiseGetFastApi("api/fast_assinatura_gateway/check_connection", `id_plataforma=${this.$route.params.id_plataforma}&id_assinatura_gateway=${this.fastGatewayAssinatura.id_assinatura_gateway}`)
      .then(res => {
        this.$store.state.fastCarregando = true;
        if (res) {
          if (res.success) {
            this.fastGatewayConexao = "sucesso"
            this.$store.state.fastCarregando = false;
          } else if (!res.success) {
            this.fastGatewayConexao = "erro"
            console.log(res.error)
            console.log(res.description)
            this.exibeToasty(res.error + " " + res.description, "error")
            this.$store.state.fastCarregando = false;
          }
        } else {
          this.exibeToasty("Erro ao verificar conexão", "error")
          this.$store.state.fastCarregando = false;
        }
      }).catch(e => {
        console.log(e);
        this.exibeToasty(e, "error");
        this.$store.state.fastCarregando = false;
      })
    },
    postAtualizaDadosGateway(){
      this.$store.state.fastCarregando = true;
      let obj = { 
        id_assinatura_gateway: this.fastGatewayAssinatura.id_assinatura_gateway,
        id_plataforma: this.$route.params.id_plataforma,
        client_id: this.fastGatewayAssinatura.client_id ? this.fastGatewayAssinatura.client_id : null,
        client_secret: this.fastGatewayAssinatura.client_secret ? this.fastGatewayAssinatura.client_secret : null
      }
      this.promisePostFastApi(obj, "api/fast_assinatura_gateway/atualiza")
          .then(res => {
            if (res.success) {
              this.exibeToasty("Alterado com sucesso", "success")
              this.hideModal('modalConfigurarGateway')
              this.getGatewaysAssinatura();
              this.$store.state.fastCarregando = false;
            } else {
              console.log(res.error)
              console.log(res.description)    
              this.exibeToasty(res.error + " " + res.description, "error")
              this.$store.state.fastCarregando = false;   
            }
          })
          .catch(e => {
            console.log(e);
            this.exibeToasty(e, "error");
            this.$store.state.fastCarregando = false;
          })
    },
    postRestauraPadraoGateway(){
      this.$store.state.fastCarregando = true;
      let obj = { 
        id_assinatura_gateway: this.fastGatewayAssinatura.id_assinatura_gateway,
        id_plataforma: this.$route.params.id_plataforma,
        voltar_config_padrao: true
      }
      this.promisePostFastApi(obj, "api/fast_assinatura_gateway/atualiza")
          .then(res => {
            console.log(res)
            if (res.success) {
              this.exibeToasty("Alterado com sucesso", "success")
              this.hideModal('modalRestaurarConfiguracoes')
              this.hideModal('modalConfigurarGateway')
              this.getGatewaysAssinatura();
              this.$store.state.fastCarregando = false;
            } else {
              console.log(res.error)
              console.log(res.description)    
              this.exibeToasty(res.error + " " + res.description, "error")
              this.$store.state.fastCarregando = false;  
            }
          })
          .catch(e => {
            console.log(e);
            this.exibeToasty(e, "error");
          })
    },
    async postGeraTokenWeb(){
      this.$store.state.fastCarregando = true;
      return new Promise(async (resolve, reject) => {
      try {
        let resp = await fetch(
          settings.endApiFastEad + `api/fast_assinatura_gateway/gerar_webhook_url?id_plataforma=${this.$route.params.id_plataforma}&id_assinatura_gateway=${this.fastGatewayAssinatura.id_assinatura_gateway}`,
          this.fastAjaxOptions("POST", "")
        )
        let json = await resp.json();
        if (json.sucess == false) {
          console.log(json.error)
          console.log(json.description)    
          this.exibeToasty(json.error + " " + json.description, "error")
          resolve(json)
          this.$store.state.fastCarregando = false
        } else {
          console.log("json teste: " + json.webhook_url)
          this.fastTokenWebhook = json.webhook_url;
          this.exibeToasty("Token gerado com sucesso", "success")
          resolve(json);
          this.getGatewaysAssinatura()
          this.$store.state.fastCarregando = false;
        }
      } catch (e) {
        console.log(e);
        exibeToasty(e, "error");
        reject(e);
      }})
    },
    exibeModalConfigurarGateway(gateway){
      this.$store.state.fastCarregando = true;
      this.fastGatewayAssinatura = gateway;
      this.showModal('modalConfigurarGateway');
      this.$store.state.fastCarregando = false;
    },
    exibeModalRestaurarConfiguracoes(){
      this.$store.state.fastCarregando = true;
      this.showModal('modalRestaurarConfiguracoes');
      this.$store.state.fastCarregando = false;
    },
    exibeModalConfigurarWebhook(gateway){
      this.$store.state.fastCarregando = true;
      this.fastGatewayAssinatura = gateway;
      this.showModal('modalConfigurarWebhook');
      this.$store.state.fastCarregando = false;
    },
    exibeModalGerarWebhook(){
      this.$store.state.fastCarregando = true;
      this.fastTokenWebhook = "";
      this.showModal('modalGerarWebhook');
      this.$store.state.fastCarregando = false;
    },
    exibeModalVerificarConexao(gateway){
      this.$store.state.fastCarregando = true;
      this.fastGatewayAssinatura = gateway;
      this.getVerificaConexaoGatewayAssinatura();
      this.showModal('modalVerificarConexao');
      this.$store.state.fastCarregando = false;
    },
    copiaURLWebhook() {
      this.$refs.urlwebhook.focus();
      document.execCommand('copy');
      this.exibeToasty("URL de Webhook do gateway de assinaturas copiado para área de transferências", "success")
    },
  },
};
</script>

<style scope>
.blink_me {
  animation: destacaBotao 500ms alternate infinite ease-in;
}

/* Ajustes de conflito no editor de texto */
/* CSS do fastead faz editor Quill ficar bugada para edição */
[contenteditable="true"] {
  display: block;
}
/* CSS do fastead faz imagem ficar 100% sempre */
[contenteditable="true"] img {
  width: auto;
}
.fd-app-curso-aulas .fd-app-curso-aulas-list .fd-app-curso-aulas-list-item,
.fd-app-meus-cursos .fd-app-curso-aulas-list .fd-app-curso-aulas-list-item {
  font-family: initial;
}

.ql-container {
  height: auto !important;
}

@keyframes destacaBotao {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.1);
  }
}
.tabs-component {
  margin: 1em 0;
}

.tabs-component-tabs {
  border: solid 1px #ddd;
  border-radius: 6px;
  margin-bottom: 5px;
}

@media (min-width: 992px) {
  .tabs-component-tabs {
    border: 0;
    align-items: stretch;
    display: flex;
    justify-content: flex-start;
    margin-bottom: -1px;
  }
}

.tabs-component-tab {
  color: #999;
  font-size: 14px;
  font-weight: 600;
  margin-right: 0;
  list-style: none;
}

.tabs-component-tab:hover {
  color: #666;
}

.tabs-component-tab.is-active {
  color: #000;
}

.tabs-component-tab.is-disabled * {
  color: #cdcdcd;
  cursor: not-allowed !important;
}

@media (min-width: 992px) {
  .tabs-component-tab {
    background-color: #fff;
    border: solid 1px #ddd;
    border-radius: 3px 3px 0 0;
    margin-right: 0.5em;
    transform: translateY(0px);
    transition: transform 0.3s ease;
  }

  .tabs-component-tab.is-active {
    border-bottom: solid 1px #fff;
    z-index: 2;
    transform: translateY(0);
  }
}

.tabs-component-tab-a {
  align-items: center;
  color: inherit;
  display: flex;
  padding: 0.75em 1em;
  text-decoration: none;
}

.tabs-component-panels {
  padding: 4em 0;
}

@media (min-width: 992px) {
  .tabs-component-panels {
    border-top-left-radius: 0;
    background-color: #fff;
    border: solid 1px #ddd;
    border-radius: 0 6px 6px 6px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
    padding: 2em;
  }
}
.prefix,
.suffix {
  align-items: center;
  border-radius: 1.25rem;
  display: flex;
  font-size: 0.75rem;
  flex-shrink: 0;
  height: 1.25rem;
  justify-content: center;
  line-height: 1.25rem;
  min-width: 1.25rem;
  padding: 0 0.1em;
}

.prefix {
  background-color: #d1e8eb;
  color: #0c5174;
  margin-right: 0.35em;
}

.suffix {
  background-color: #c03;
  color: #fff;
  margin-left: 0.35em;
}

@media (min-width: 992px) {
  .suffix {
    position: absolute;
    right: -0.6em;
    top: -0.725em;
  }
}
/* Tree */
.icon:hover {
  cursor: pointer;
}

.muted {
  color: gray;
  font-size: 80%;
}

/* Tooltip */
.tooltip {
  display: block !important;
  z-index: 10000;
}

.tooltip .tooltip-inner {
  background: #fff;
  color: #000;
  border-radius: 16px;
  padding: 5px 10px 4px;
  -webkit-box-shadow: 1px 1px 5px 0px rgba(50, 50, 50, 0.75);
  -moz-box-shadow: 1px 1px 5px 0px rgba(50, 50, 50, 0.75);
  box-shadow: 1px 1px 5px 0px rgba(50, 50, 50, 0.75);
  width: 300px;
  min-width: 300px;
}

.tooltip .tooltip-arrow {
  width: 0;
  height: 0;
  border-style: solid;
  position: absolute;
  margin: 5px;
  border-color: black;
  z-index: 1;
}

.tooltip[x-placement^="top"] {
  margin-bottom: 5px;
}

.tooltip[x-placement^="top"] .tooltip-arrow {
  border-width: 5px 5px 0 5px;
  border-left-color: transparent !important;
  border-right-color: transparent !important;
  border-bottom-color: transparent !important;
  bottom: -5px;
  left: calc(50% - 5px);
  margin-top: 0;
  margin-bottom: 0;
}

.tooltip[x-placement^="bottom"] {
  margin-top: 5px;
}

.tooltip[x-placement^="bottom"] .tooltip-arrow {
  border-width: 0 5px 5px 5px;
  border-left-color: transparent !important;
  border-right-color: transparent !important;
  border-top-color: transparent !important;
  top: -5px;
  left: calc(50% - 5px);
  margin-top: 0;
  margin-bottom: 0;
}

.tooltip[x-placement^="right"] {
  margin-left: 5px;
}

.tooltip[x-placement^="right"] .tooltip-arrow {
  border-width: 5px 5px 5px 0;
  border-left-color: transparent !important;
  border-top-color: transparent !important;
  border-bottom-color: transparent !important;
  left: -5px;
  top: calc(50% - 5px);
  margin-left: 0;
  margin-right: 0;
}

.tooltip[x-placement^="left"] {
  margin-right: 5px;
}

.tooltip[x-placement^="left"] .tooltip-arrow {
  border-width: 5px 0 5px 5px;
  border-top-color: transparent !important;
  border-right-color: transparent !important;
  border-bottom-color: transparent !important;
  right: -5px;
  top: calc(50% - 5px);
  margin-left: 0;
  margin-right: 0;
}

.tooltip.popover .popover-inner {
  background: #f9f9f9;
  color: black;
  padding: 24px;
  border-radius: 5px;
  box-shadow: 0 5px 30px rgba(black, 0.1);
}

.tooltip.popover .popover-arrow {
  border-color: #f9f9f9;
}

.tooltip[aria-hidden="true"] {
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.15s, visibility 0.15s;
}

.tooltip[aria-hidden="false"] {
  visibility: visible;
  opacity: 1;
  transition: opacity 0.15s;
}
</style>
